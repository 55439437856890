/*

Lots listings

*/

$(function(global) {
	$("#mainSearchPriceModalMin").keydown(function(event) {
		if (event.which == 13) {
			event.preventDefault();
			$('#mainSearchPriceModalMax').select();
		}
	});
	$("#mainSearchPriceModalMax").keydown(function(event) {
		if (event.which == 13) {
			event.preventDefault();
			$('#mainSearchPriceModalButton').click();
		}
	});

	$('#mainSearchPriceModalWindow').on('shown.bs.modal', function (e) {
		$("#mainSearchPriceModalMin").select();
	});
	
	lotsListOnload();
});

global.lotsListOnload = lotsListOnload;
function lotsListOnload(section = '') {
	if (section.length > 0) {
		section = section + ' ';
	}
	$(section + '.observestar').click(function() {
		lotObserve($(this).data('locale'), $(this).data('auction'), $(this).data('lot'));
	});
	
	let mobile = new MobileDetect(window.navigator.userAgent);
	if (mobile.mobile()) {
		$(section + '.flipitem').each(function(i, e) {
			var mc = new Hammer(this);
			var element = $(e);
			mc.on("swiperight swipeleft", function(e) {
				$(element).flip('toggle');
				return false;
			});
		});
	}
	
	$(section + '.imgbox .images').css({'display': 'flex'});
	$(section + '.flipitem').flip({trigger: 'hover'});
}

global.auctionPrintoutOpen = auctionPrintoutOpen;
function auctionPrintoutOpen() {
	var lots = $('#mainlotslistcontent .lotinfo');
	if (typeof lots[0] !== 'undefined') {
		var nra = parseInt($(lots[0]).data('nr'));
		var nrb = parseInt($(lots[(lots.length - 1)]).data('nr'));
		if (nra > 0 && nrb > 0) {
			$('#auctionPrintoutModalNumbers').val(nra + '-' + nrb);
		} else {
			$('#auctionPrintoutModalNumbers').val('1-10');
		}
	}
	$('#auctionPrintoutModalWindow').modal('show');
}

global.auctionPrintoutMake = auctionPrintoutMake;
var auctionPrintoutLoaded = 0;
var auctionPrintoutTotal = 0;
var auctionPrintoutInterval = null; 
function auctionPrintoutMake(lang, auction) {
	$('#auctionPrintoutModalNumbers').removeClass('is-invalid');
	var nrs = $('#auctionPrintoutModalNumbers').val().trim();
	if (!/^[0-9\-,]+$/.test(nrs)) {
		$('#auctionPrintoutModalNumbers').addClass('is-invalid').select();
		return;
	}
	var images = parseInt($('#auctionPrintoutModalImages').prop('checked') ? 1 : 0);
	var data = {
		nrs: nrs,
		images: images,
		//	hires: parseInt($('#auctionPrintoutModalHires').prop('checked') ? 1 : 0),
		//	offers: parseInt($('#auctionPrintoutModalOffers').prop('checked') ? 1 : 0),
		favourite: parseInt($('#auctionPrintoutModalFavourite').prop('checked') ? 1 : 0),
		notes: parseInt($('#auctionPrintoutModalNotes').prop('checked') ? 1 : 0)
	}
	var $btn = $('#auctionPrintoutModalButton');
	$btn.html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ' + $btn.data('loading-text')).prop('disabled', true);
	$.ajax({
		url: '/' + lang + '/ajax/auction/printout/' + auction,
		dataType: 'json',
		data: 'data=' + JSON.stringify(data),
		method: 'POST',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		if (jqXHR.status != 200) {
			toaster('danger', '', json.message);
			return;
		}
		$('#mainpreloader').show();
		var page = 1;
		var doc = new jsPDF({lineHeight: 1.2});
		var lot = null;
		var cnt = 0;
		var i = 0;
		var c = 0;
		var split = null;
		var dimensions = null;
		var height = 0;
		var test = 0;
		var topinitial = 40;
		var left = (images > 0 ? 60 : 10);
		var textbox = (images > 0 ? 140 : 190);
		var pageheight = 280;
		var spaceheight = 1.5;
		var priceheight = 2;
		var gapeheight = 5;
		var minboxheight = 28;
		var boxheight = 0;
		var sectionheight = 0;
		var top = topinitial;
		var imgpos = {1: []};
		var dname = null;
		var sname = null;
		var dtext = null;
		var stext = null;
		var right = 0;
		var tmpleft = 0;
		var tmp = {};
		for (i in json.lots) {
			lot = json.lots[i];
			
			doc.setFont('Lato-Regular', 'normal');
			doc.setFontSize(9);
			
			sname = doc.splitTextToSize(lot.name, textbox);
			dname = doc.getTextDimensions(sname);
			stext = doc.splitTextToSize(lot.text + (lot.user.info > 0 ? '\n' + '»»» ' + lot.user.note : ''), textbox);
			dtext = doc.getTextDimensions(stext);
			
			test = top + dname.h + dtext.h + (spaceheight * 2) + priceheight;
			if (images && test < minboxheight) {
				test = minboxheight;
			}
			if (test > pageheight) {
				// new page
				doc.addPage('A4', 'p');
				cnt = 0;
				top = topinitial;
				page = page + 1;
				imgpos[page] = [];
			}
			
			boxheight = 0;
			sectionheight = 0;
			for (c in lot.img) {
				tmp = {
					url: lot.img[c],
		top: top - 2,
		left: (9 + (c * 23) + (lot.img.length == 1 ? 11 : 0)),
		width: (lot.img.length == 1 ? 22 : 22),
		maxheight: minboxheight - 3,
		data: ''
				};
				imgpos[page].push(tmp);
				auctionPrintoutTotal = auctionPrintoutTotal + 1;
			}
			
			doc.setFont('Lato-Bold', 'bold');
			doc.text(left, top, lot.nr + '.');
			doc.setFont('Lato-Regular', 'normal');
			doc.text(left + 10, top, sname);
			sectionheight = dname.h + spaceheight;
			boxheight = boxheight + sectionheight;
			top = top + sectionheight;
			
			doc.text(left, top, stext, {align: 'justify', maxWidth: textbox});
			sectionheight = dtext.h + spaceheight;
			boxheight = boxheight + sectionheight;
			top = top + sectionheight;
			
			tmpleft = left;
			if (lot.condition.length > 0) {
				stext = $('#auctionPrintoutModalWindow').data('text-condition') + ':';
				dtext = doc.getTextDimensions(stext);
				doc.setTextColor(119, 119, 119);
				doc.setFont('Lato-Regular', 'normal');
				doc.text(tmpleft, top, stext);
				tmpleft = tmpleft + dtext.w + 1;
				
				stext = lot.condition + (lot.hot.length > 0 ? ' ' + lot.hot : '') + (lot.rare.length > 0 ? ' ' + lot.rare : '');
				dtext = doc.getTextDimensions(stext);
				doc.setTextColor(0, 0, 0);
				doc.setFont('Lato-Bold', 'bold');
				doc.text(tmpleft, top, stext);
				tmpleft = tmpleft + dtext.w + 2;
			}
			if (lot.droit > 0) {
				doc.setTextColor(119, 119, 119);
				doc.setFont('Lato-Regular', 'normal');
				doc.text(tmpleft, top, 'Droit de suite');
			}
			
			if (lot.withdrawn > 0) {
				doc.setTextColor(119, 119, 119);
				doc.setFont('Lato-Regular', 'normal');
				doc.text(200, top, $('#auctionPrintoutModalWindow').data('text-withdrawn'), 'right');
			} else {
				right = 0;
				stext = json.currency.acr;
				dtext = doc.getTextDimensions(stext);
				doc.setTextColor(119, 119, 119);
				doc.setFont('Lato-Regular', 'normal');
				doc.text(200, top, stext, 'right');
				right = right + dtext.w + 0.5;
				
				if (json.auction.status <= 2) {
					stext = Math.round(lot.start / 100).formatMoney(0, '.', ' ');
				} else {
					if (lot.status == 3) {
						stext = Math.round(lot.hammer / 100).formatMoney(0, '.', ' ');
					} else {
						stext = Math.round(lot.start / 100).formatMoney(0, '.', ' ');
					}
				}
				dtext = doc.getTextDimensions(stext);
				doc.setTextColor(0, 0, 0);
				doc.setFont('Lato-Bold', 'bold');
				doc.text(200 - right, top, stext, 'right');
				right = right + dtext.w + 1;
				
				if (json.auction.status <= 2 || lot.status == 3) {
					stext = $('#auctionPrintoutModalWindow').data('text-price') + ':';
				} else {
					stext = '(' + $('#auctionPrintoutModalWindow').data('text-noofers') + ') ';
				}
				dtext = doc.getTextDimensions(stext);
				doc.setTextColor(119, 119, 119);
				doc.setFont('Lato-Regular', 'normal');
				doc.text(200 - right, top, stext, 'right');
			}
			
			doc.setTextColor(0, 0, 0);
			doc.setFont('Lato-Regular', 'normal');
			sectionheight = priceheight + gapeheight;
			boxheight = boxheight + sectionheight;
			top = top + sectionheight;
			if (boxheight < minboxheight) {
				top = top + (minboxheight - boxheight);
			}
			
			if (cnt == 0) {
				// header
				doc.setDrawColor(0, 0, 0);
				doc.setLineWidth(0.1);
				doc.line(10, 30, 200, 30);
				doc.addImage(json.company.logo, 'PNG', 10, 8, 0, 16, '', 'NONE', 0);
				doc.setFont('Lato-Bold', 'bold');
				doc.setFontSize(10);
				doc.text(200, 13, json.auction.date, 'right');
				doc.setFont('Lato-Regular', 'normal');
				doc.setFontSize(11);
				doc.text(200, 22, json.auction.name, 'right');
			}
			
			cnt = cnt + 1;
		}
		if (images) {
			auctionPrintoutImgAdd(doc, imgpos);
		}
		auctionPrintoutInterval = setInterval(function() {
			if (images == 0 || auctionPrintoutLoaded >= auctionPrintoutTotal) {
				$('#mainpreloader').hide();
				clearInterval(auctionPrintoutInterval);
				auctionPrintoutInterval = null;
				doc.save(json.auction.slug + '.pdf');
				$('#auctionPrintoutModalWindow').modal('hide');
			}
		}, 500);
	})
	.fail(function (jqXHR, textStatus, errorThrown) {
		toaster('danger', '', translate('blad'));
	})
	.always(function() {
		$btn.html($btn.data('normal-text')).prop('disabled', false);
	});
}

function auctionPrintoutImgAdd(doc, imgpos) {
	var p = 0;
	var i = 0;
	var dim = null;
	var prop = 0;
	var w = 0;
	var h = 0;
	var v = 0;
	var l = 0;
	for (p in imgpos) {
		for (i in imgpos[p]) {
			auctionPrintoutImgGet(imgpos[p][i].url, p, i, function(ret) {
				// firefox hack :(
				setTimeout(function() {
					try {
						dim = doc.getImageProperties(ret.d);
					} catch (e) {
						// no access, 404
						auctionPrintoutLoaded = auctionPrintoutLoaded + 1;
						return 0;
					}
					//	console.log('IMG page: ' + p + '/' + ret.p, ' img: '+ i + '/' + ret.i + ' top: ' + imgpos[ret.p][ret.i].top);
					doc.setPage(ret.p);
					prop = dim.width / dim.height;
					w = imgpos[ret.p][ret.i].width;
					v = imgpos[ret.p][ret.i].width / prop;
					//	console.log('w ' + imgpos[ret.p][ret.i].width + ' h ' + v + ' prop ' + prop + ' max ' + imgpos[ret.p][ret.i].maxheight);
					if (imgpos[ret.p][ret.i].width / prop > imgpos[ret.p][ret.i].maxheight) {
						w = w * (imgpos[ret.p][ret.i].maxheight / v);
					}
					l = 0;
					if (prop < 0.7) {
						w = w * prop;
						l = (imgpos[ret.p][ret.i].width - w) / 2;
					}
					doc.addImage(ret.d, 'JPEG', imgpos[ret.p][ret.i].left + l, imgpos[ret.p][ret.i].top, w, h, '', 'NONE');
					auctionPrintoutLoaded = auctionPrintoutLoaded + 1;
				}, 100);
			});
		}
	}
}

function auctionPrintoutImgGet(url, p, i, callback) {
	var ret = {
		p: p,
		i: i,
		d: ''
	};
	var img = new Image();
	img.src = url;
	img.onload = function() {
		ret.d = img;
		callback(ret);
	};
}

EndlessScroll = function(type, url, grid) {
	this.type = type;
	this.grid = grid;
	this.active = true;
	this.part = 0;
	this.last = 0;
	this.url = url;
}
EndlessScroll.prototype.show = function(bind) {
	var preload = 0;
	if (bind == 'load' && parseInt($('#scrollcounter').val()) > 0) {
		if (parseInt($('#scrollheight').val()) > 0) {
			$('#mainlotslistcontent').css({height: parseInt($('#scrollheight').val()) + 'px'});
		}
		this.part = parseInt($('#scrollcounter').val());
		preload = this.part;
		$('html').scrollTop(parseInt($('#scrollposition').val()));
		$('#mainpreloader').show();
		this.active = true;
	}
	if (bind == 'scroll') {
		$('#scrollposition').val($('html').scrollTop());
	}
	if ((($(window).scrollTop() + window.innerHeight) >= $('#mainlotslistcontent').offset().top + $('#mainlotslistcontent').outerHeight() - window.innerHeight) || preload > 0) {
		if (!this.active) {
			return;
		}
		//	console.log('preload: ' + preload);
		this.active = false;
		$('#endlessspinner').removeClass('d-none');
		console.log('part: ' + this.part + ' last: ' + this.last);
		if (this.part == this.last || preload > 0) {
			this.part = this.part + 1;
		} else {
			this.part = this.last;
		}
		var scrollpart = 'scrollpart' + this.part;
		var url = this.url.replace('###', preload);
		url = url.replace('@@@', this.part);
		url = url.replace(/&quot;/g, '%22');
		preload = 0;
		
		scroll = this;
		$.ajax({
			url: url,
		dataType: 'json',
		method: 'GET',
		cache: false,
		})
		.done(function (data) {
			if (scroll.last + 1 != data.part && data.preload == 0) {
				console.log('scroll again ! ' + data.part);
				scroll.active = true;
				return;
			}
			var $content = $('<div id="' + scrollpart + '">' + data.lots.html + '<div>');
			scroll.grid.append($content).masonry('appended', $content);
			lotsListOnload('#' + scrollpart);
			$('#endlessspinner').addClass('d-none');
			
			if (data.preload > 0) {
				$('#mainpreloader').hide();
			}
			
			if (data.lastpart > 0 || data.lastload > 0) {
				//	$(window).off('scroll');
				$('#endlessspinner').remove();
			} else {
				scroll.active = true;
			}
			
			$('#scrollcounter').val(scroll.part);
			$('#scrollheight').val($('#mainlotslistcontent').outerHeight());
			
			if (scroll.type == 'auction') {
				if (data.next.id > 0) {
					$('#scrollnextcateogory').removeClass('d-none');
					$('#scrollnextcateogory div.goto').html('<a href="' + data.next.url + '" class="btn btn-link btn-sm">' + data.next.name + '</a>');
				}
				if (data.related.count > 0) {
					$('#relatedContainer').html(data.related.html);
					lotsListOnload('#relatedContainer');
				}
				if (data.archive.count > 0) {
					$('#archiveContainer').html(data.archive.html);
					lotsListOnload('#archiveContainer');
				}
			}
			if (data.part > 1) {
				gtag('event', 'page_view');
				//	ga('send', 'pageview');
			}
			
			//		window.history.replaceState({} , '', window.location.href.replace(/\?p=[0-9]+/, '') + '?p=' + scroll.part);
			scroll.last = data.part;
		});
	}
}

global.resultsSave = resultsSave;
function resultsSave(lang, auction, exchangedata) {
	$.ajax({
		url: '/' + lang + '/ajax/auctionresults/' + auction,
		data: '',
		dataType: 'json',
		method: 'GET',
		cache: false
	})
	.done(function (json, textStatus, jqXHR) {
		if (jqXHR.status != 200) {
			toaster('danger', '', json.message);
			return;
		}
		var doc = new jsPDF();
		var html = '';
		var i = 0;
		var exprice = {};
		var page = 0;
		var cnt = 0;
		var row = 0;
		var line = 0;
		var top = 30;
		var left = 15;
		var col = 40;
		var lots = 60;
		var cols = 5;
		var height = 4;
		var total = Object.keys(json.lots).length;
		var pages = Math.ceil(total / (lots * cols));
		for (i in json.lots) {
			if (cnt >= lots * cols) {
				cnt = 0;
			}
			if (cnt == 0) {
				page = page + 1;
				if (page > 1) {
					doc.addPage('A4', 'p');
				}
				doc.setFontSize(20);
				doc.text(14, 18, json.name);
				doc.setFontSize(12);
				doc.setTextColor(150);
				doc.text(100, 287, page + ' / ' + pages);
				doc.setFontSize(9);
				doc.setTextColor(0);
				doc.text(185, 14, json.date);
				doc.text(194, 20, json.currency);
				doc.setLineWidth(0.3);
				doc.line(10, 25, 200, 25);
				row = 0;
				line = 0;
			}
			cnt = cnt + 1;
			line = line + 1;
			//	exprice = exchange(exchangedata, json.lots[i].price);
			doc.setFontType("bold");
			doc.text(left + (col * row), top + (line * height), json.lots[i].nr + (/^[0-9]{1,3}$/.test(json.lots[i].letter) ? '.' : '') + json.lots[i].letter);
			doc.setFontType("normal");
			doc.text(left + (col * row) + 24, top + (line * height), (json.lots[i].status == 3 && json.lots[i].withdrawn == 0 ? Math.round(json.lots[i].price / 100).formatMoney(0, '.', ' ') : '-'), null, null, 'right');
			if (cnt % lots == 0) {
				row = row + 1;
				line = 0;
			}
		}
		doc.save('A' + auction + '.pdf');
	})
	.fail(function (jqXHR, textStatus, errorThrown) {
		toaster('danger', '', translate('blad'));
	});
}

global.companyFavouriteOpen = companyFavouriteOpen;
function companyFavouriteOpen() {
	if ($('#companyfav .fa').hasClass('active')) {
		companyFavouriteSave(2);
	} else {
		$('#companyFavouriteModal').modal('show');
	}
}

global.companyFavouriteSave = companyFavouriteSave;
function companyFavouriteSave(mode) {
	var id = $('#companyFavouriteModal').data('id');
	var lang = $('#companyFavouriteModal').data('lang');
	$('#companyfav .icon').addClass('spin');
	$.ajax({
		url: '/' + lang + '/ajax/notification/companies/update/' + mode + '/' + id,
		dataType: 'json',
		method: 'POST',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		var i = 0;
		if (jqXHR.status != 200) {
			toaster('danger', '', json.message);
			return;
		}
		$('#companyFavouriteModal').modal('hide');
		if (mode == 1) {
			$('#companyfav .icon').text('star');
		} else {
			$('#companyfav .icon').text('star_border');
		}
	})
	.fail(function (jqXHR, textStatus, errorThrown) {
		toaster('danger', '', translate('blad'));
	})
	.always(function() {
		$('#companyfav .icon').removeClass('spin');
	});
}

global.userSearchAdd = userSearchAdd;
function userSearchAdd(lang, term, comment = '') {
	$.ajax({
		url: '/' + lang + '/ajax/usersearch/add/',
		data: 'term=' + encodeURIComponent(term) + '&comment=' + encodeURIComponent(comment),
		dataType: 'json',
		method: 'POST',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		var i = 0;
		if (jqXHR.status != 200) {
			toaster('danger', '', json.message);
			return;
		}
		if (json.status == 1 || json.status == 2) {
			toaster('success', '', translate('zapisano'));
			if (json.status != 1) {
				$('.usersearch .helptext').hide();
			}
		}
	})
	.fail(function (jqXHR, textStatus, errorThrown) {
		toaster('danger', '', translate('blad'));
	});
}
